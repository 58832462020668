@import "../../styles/helpers";

.search {
  // margin-left: 22px
  position: relative;
  flex-shrink: 1;
  max-width: 356px;
  @include d {
    width: auto;
    margin-left: 11px; }
  @include t {
    display: none; }
  @include m {
    display: block;
    width: 100%;
    margin: auto 0 0; }

  .result {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 42px;
    height: 100% !important;

    svg {
      fill: $neutrals4;
      transition: fill .2s; }

    &:hover {
      svg {
        fill: $primary; } } }

  .input {
    width: 100%;
    height: 44px;
    padding: 5px 42px 0 34px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
      color: $neutrals4; }
    @include dark {
      border-color: $neutrals3;
      color: $neutrals8; }

    &:focus {
      border-color: $neutrals4; } }

  .no_left {
    padding-left: 10px; } }

.left {
  left: 0; }

.right {
  right: 0; }
