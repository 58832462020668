// media queries
$t: 1023;
$input-height: 48px;
$items-gap: 23px;
$containers-spacing: 20px;
$border-radius: 16px;

@mixin w {
  @media only screen and (max-width: 1419px) {
    @content; } }

@mixin x {
  @media only screen and (max-width: 1339px) {
    @content; } }

@mixin d {
  @media only screen and (max-width: 1179px) {
    @content; } }

@mixin t {
  @media only screen and (max-width: $t+"px") {
    @content; } }

@mixin tmin {
  @media only screen and (min-width: $t+"px") {
    @content; } }

@mixin m {
  @media only screen and (max-width: 767px) {
    @content; } }

@mixin a {
  @media only screen and (max-width: 639px) {
    @content; } }

@mixin s {
  @media only screen and (max-width: 474px) {
    @content; } }

// fonts
@mixin dm-sans {
  font-family: 'DM Sans', sans-serif; }

@mixin poppins {
  font-family: 'Poppins', sans-serif; }

// colors
$white: #ffffff;
$primary: #0FC0CB;
$primary-light: #0FC0CB;
$primary-dark: #0b949c;
$primary-deep-dark: rgba(15, 192, 203, 0.1);
$blue: #3772FF;
$blue2: #1286E3;
$blue-light: #4BC9F0;
$purple: #7863EA;
$purple-light: #7863EA;
$purple-dark: #4E38C3;
$pink: #EF466F;
$pink_dark: rgba(239, 70, 111, 0.1);
$green: #45B26B;
$green_light: rgba(69, 179, 107, 0.2);
$asphalt: #E4D7CF;
$yellow: #FFD166;
$yellow_light: rgba(255, 209, 102, 0.2);
$yellow_dark: rgba(239, 232, 70, 0.1);
$navy_blue: #2B344B;
$navy_blue_1: #212735;

$neutrals1: #141416;
$neutrals1_5: #1A1B1E;
$neutrals1_6: #161921;
$neutrals2: #23262F;
$neutrals2_5: #212531;
$neutrals3: #353945;
$neutrals4: #777E90;
$neutrals5: #B1B5C3;
$neutrals6: #E6E8EC;
$neutrals7: #F4F5F6;
$neutrals8: #FCFCFD;
$blue-button-color: #1BA9D6;

// typography
@mixin body-1 {
  font-size: 24px;
  line-height: (32/24);
  letter-spacing: -.01em; }

@mixin body-bold-1 {
  font-size: 24px;
  line-height: (32/24);
  font-weight: 600;
  letter-spacing: -.01em; }

@mixin body-2 {
  font-size: 16px;
  line-height: (24/16); }

@mixin body-bold-2 {
  font-size: 16px;
  line-height: (24/16);
  font-weight: 500; }

@mixin caption-1 {
  font-size: 14px;
  line-height: (24/14); }

@mixin caption-bold-1 {
  font-size: 14px;
  line-height: (24/14);
  font-weight: 500; }

@mixin caption-2 {
  font-size: 12px;
  line-height: (20/12); }

@mixin caption-bold-2 {
  font-size: 12px;
  line-height: (20/12);
  font-weight: 600; }

@mixin hairline-1 {
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase; }

@mixin hairline-2 {
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase; }

@mixin button-1 {
  @include dm-sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 1; }

@mixin button-2 {
  @include dm-sans;
  font-size: 14px;
  line-height: (16/14);
  font-weight: 700; }

@mixin button-3 {
  @include dm-sans;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700; }

//triangle
@mixin arr($width, $height, $bg, $direction) {
  width: 0px;
  height: 0px;
  border-style: solid;
  @if $direction == t {
    border-width: 0 $width / 2 + px $height + px $width / 2 + px;
    border-color: transparent transparent $bg transparent; }
  @if $direction == r {
    border-width: $height / 2 + px 0 $height / 2 + px $width + px;
    border-color: transparent transparent transparent $bg; }
  @if $direction == b {
    border-width: $height + px $width / 2 + px 0 $width / 2 + px;
    border-color: $bg transparent transparent transparent; }
  @if $direction == l {
    border-width: $height / 2 + px $width + px $height / 2 + px 0;
    border-color: transparent $bg transparent transparent; }
  @if $direction == tl {
    border-width: $height + px $width + px 0 0;
    border-color: $bg transparent transparent transparent; }
  @if $direction == tr {
    border-width: 0 $width + px $height + px 0;
    border-color: transparent $bg transparent transparent; }
  @if $direction == br {
    border-width: 0 0 $height + px $width + px;
    border-color: transparent transparent $bg transparent; }
  @if $direction == bl {
    border-width: $width + px 0 0 $height + px;
    border-color: transparent transparent transparent $bg; } }
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

// placeholder
@mixin placeholder {
  &::placeholder {
    @content; } }

// media query width
@mixin r($width) {
  @media only screen and (max-width: $width+ "px") {
    @content; } }

@mixin rmin($width) {
  @media only screen and (min-width: $width+ "px") {
    @content; } }

// theme
@mixin dark {
  //@at-root :global(.dark-mode) &
  @at-root :global() & {
    @content; } }

@mixin dark-body {
  @at-root .dark-mode {
    @content; } }

@mixin dark-common {
  @at-root .dark-mode & {
    @content; } }

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
  vertical-align: middle; }

@mixin error {
  margin-top: 10px;
  margin-bottom: 10px;
  @include caption-2;
  color: $pink;
  font-weight: 100; }
// font-size: 10px


@mixin items-list {
  display: grid;
  gap: $items-gap;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  justify-content: flex-start;
  @include d {
    grid-template-columns: repeat(2, minmax(250px, 1fr)); }
  @include m {
    grid-template-columns: repeat(1, 1fr); } }

@mixin focus-box-shadow {
  box-shadow: 0 0 0 2px $neutrals8 inset !important; }

@mixin invisible {
  color: transparent;
  user-select: none;
  max-height: 1em;
  line-height: 1;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden; }

@keyframes show {
  0% {
    opacity: 0;
    transform: scale(.9); }
  100% {
    opacity: 1;
    transform: scale(1); } }
