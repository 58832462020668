.root {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.btn {
    width: 100%;
}
