@import "../../../styles/helpers";

.search {
  margin-left: 22px;
  position: relative;
  flex-shrink: 1;
  width: 256px;
  @include d {
    width: auto;
    margin-left: 11px; }
  @include t {
    display: none !important; } }

.input {
  width: 100%;
  height: 40px !important;
  padding: 0 42px 0 16px !important;
  background: none;
  border: 2px solid $neutrals6;
  border-radius: 8px;
  @include poppins;
  @include caption-2;
  transition: border-color .2s;
  @include placeholder {
    color: $neutrals4; }
  @include dark {
    border-color: $neutrals3;
    color: $neutrals8; }

  &:focus {
    border-color: $neutrals4; } }

.result {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 0px !important;
  width: 42px;

  svg {
    fill: $neutrals4;
    transition: fill .2s; }

  &:hover {
    svg {
      fill: $primary; } }

  @include t {
    display: none; } }

.search_container {
  position: relative; }

.search_result {
  position: absolute;
  display: none;
  width: 100%;
  margin-top: 25px;
  min-height: 250px;
  background: $neutrals6;
  @include dark {
    background: $neutrals1_5; }

  &.active {
    display: block; }

  @include t {
    display: none !important; }

  .loader {
    margin-top: 30px;
    display: flex;
    justify-content: center; }

  h3 {
    margin: 1em 0;
    padding-left: 10px; } }

.search_result_item {
  width: 100%;
  display: flex;
  align-items: center;
  color: $neutrals8;

  img {
    max-width: 50px;
    width: 100%;
    max-height: 30px;
    margin: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $neutrals2_5;
    white-space: pre;
    @include caption-2; }

  &:hover {
    background-color: $neutrals4; }

  &:not(:last-child) {
    border-bottom: 1px solid $neutrals4; } }
