@import "../../styles/helpers";

.dropdown {
    position: relative;
    z-index: 3;
    &.not_active {
        .body {
            visibility: hidden; } }
    &.active {
        z-index: 10;
        @include dark {
            color: $white; }
        .head {
            background: $neutrals8;
            color: $primary;
            @include dark {
                background: $neutrals1;
                color: $neutrals8; }
            svg {
                transform: translateY(-50%) rotate(180deg);
                margin-bottom: -15px;
                fill: $primary;
                @include dark {
                    fill: $neutrals8; } } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            @include m {
                position: relative !important;
                width: 100%;
                min-width: max-content; } } } }

.selection {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.body {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    padding: 24px;
    border-radius: 24px;
    background: $neutrals8;
    box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.15);
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include dark {
        background: $neutrals1;
        box-shadow: inset 0 0 0 2px $neutrals3, 0 4px 12px rgba($neutrals1, .1); } }
