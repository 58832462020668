// common styles
html {
    overflow-x: hidden; }

body {
    min-width: 375px;
    background: $neutrals8;
    @include poppins;
    font-size: 14px;
    line-height: (24/14);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $neutrals2;
    @include dark-body {
        background: $neutrals1;
        color: $neutrals8; }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none; } } }

svg,
img {
    vertical-align: middle; }

.desktop {
    &-hide {
        @include d {
            display: none !important; } }
    &-show {
        display: none !important;
        @include d {
            display: block !important; } }
    &-text-right {
        @include d {
            text-align: right; } } }

.tablet {
    &-hide {
        @include t {
            display: none !important; } }
    &-show {
        display: none !important;
        @include t {
            display: block !important; } } }

.mobile {
    &-hide {
        @include m {
            display: none !important; } }
    &-show {
        display: none !important;
        @include m {
            display: block !important; } } }

.text-right {
    text-align: right; }

.flex {
    display: flex; }

.justify-between {
    justify-content: space-between; }
.justify-evenly {
    justify-content: space-evenly; }

.loading-circle {
    height: 100px;
    width: 100px;
    margin: 20px auto; }
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

input[disabled] {
    cursor: not-allowed; }

.ellipsis {
    @include ellipsis; }
