@import "/src/styles/helpers";

.button {
  position: relative;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.loader {
  position: absolute;
  right: 1rem;
}
