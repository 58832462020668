@import "../../styles/helpers";

.header {
    position: sticky;
    top: 0;
    z-index: 90;
    padding: 20px 0;
    border-bottom: 1px solid $neutrals6;
    background: $neutrals1;
    @include m {
        padding: 24px 0 24px;
        width: 100vw; }
    @include dark {
        border-color: $neutrals3; } }

.container {
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 40px;
    padding: 0 40px;
    @include d {
        padding: 0 20px; }
    @include m {
        padding: 0 10px; }
    & > .button {
        margin-right: 12px;
        @include m {
            display: none; } }
    & > * {
        flex-shrink: 1; } }

.logo {
    position: relative;
    z-index: 12;
    display: inline-block;
    flex-shrink: 1;
    width: auto;
    margin-right: 10px;
    @include d {
        margin-right: auto; }
    img {
        width: 100%;
        height: 23.52px;
        @include m {
            height: 32px; } } }

.wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    @include m {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        align-items: stretch;
        height: 100vh;
        justify-content: space-between;
        margin: 0;
        padding: 140px 32px 40px;
        box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
        background: $neutrals8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        @include dark {
            background: $neutrals1; }
        &.active {
            visibility: visible;
            opacity: 1;
            padding-top: 30px;
            margin-top: 85px;
            border-top: 1px solid $neutrals4;
            height: calc(100vh - 40px);
            overflow: auto; } }

    .button {
        display: none;
        @include m {
            display: inline-flex;
            margin-top: 16px; } } }

.nav {
    display: flex;
    margin-right: auto;

    @include m {
        display: flex;
        flex-direction: column;
        margin: 0 0 40px;
        border: none; }

    &:before {
        content: " ";
        width: 2px;
        background: $neutrals6;
        margin-top: .5rem;
        margin-bottom: .5rem;
        margin-left: 11px;
        @include m {
            display: none; }
        @include dark {
            background: $neutrals3; } } }
.dHead {
    display: flex;
    align-items: center; }

.link {
    margin-left: 22px;
    vertical-align: text-top;
    @include dm-sans;
    line-height: 40px;
    color: $neutrals4;
    transition: color .2s;
    @include d {
        margin-left: 11px; }
    @include m {
        margin-left: 0;
        font-size: 32px;
        line-height: 64px; }
    &:hover,
    &.active {
        color: $neutrals8;

        .icon {
            stroke: $neutrals8; } }


    &:nth-child(n+7) {
        display: none;
        @include m {
            display: block; } }

    .icon {
        stroke: $neutrals4; } }

.search {
    margin-left: 22px;
    position: relative;
    flex-shrink: 1;
    width: 256px;
    @include d {
        width: auto;
        margin-left: 11px; }
    @include t {
        display: none; } }

.input {
    width: 100%;
    height: 40px !important;
    padding: 0 42px 0 16px !important;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 8px;
    @include poppins;
    @include caption-2;
    transition: border-color .2s;
    @include placeholder {
        color: $neutrals4; }
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-top: 0px !important;
    width: 42px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $primary; } } }

.burger {
    display: none;
    @include m {
        display: block;
        position: relative;
        margin: 0 10px;
        width: 32px;
        height: 32px;
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        &.active {
            &:before {
                transform: translateY(0) rotate(-45deg); }
            &:after {
                transform: translateY(0) rotate(45deg); }
            border: 1px solid $neutrals4;
            border-radius: 72px;
            height: 36px;
            width: 36px;

            &:before,
            &:after {
                background: $neutrals2;
                @include dark {
                    background: $neutrals8; } } }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 16px;
            left: 6px;
            width: 20px;
            height: 2px;
            background: $neutrals4;
            border-radius: 2px;
            transition: transform .2s; }
        &:before {
            transform: translateY(-4px); }
        &:after {
            transform: translateY(3px); } } }

.connect {
    @include dark {
        box-shadow: 0 0 0 2px $neutrals4 inset; } }

.user_menu {
    display: flex;
    justify-content: space-between;
    gap: 12px; }

.wallet_modal {
    max-width: 520px;
    padding: 16px;
    @include dark {
        background-color: $neutrals1_6 !important; } }

.wallet_modal_container {
    width: 100%; }

.dropdown_body {
    width: max-content;
    display: flex;
    flex-direction: column;
    padding: 20px !important;
    margin-top: 20px;
    min-width: 250px;
    @include dark {
        box-shadow: none !important;
        background: $neutrals1_6 !important; }

    .link {
        margin-left: 0px;
        padding: 10px;
        display: flex;
        gap: 10px;
        align-items: center;

        @include m {
            font-size: 18px; }

        &:hover {
            background: $neutrals2_5;
            border-radius: 8px; }

        .icon {
            width: 38px;
            height: 38px;
            display: inline-block;
            border-radius: 30px;
            background: $neutrals3;
            font-size: 18px; } } }

.subactive {
    background: $neutrals2_5;
    border-radius: 8px; }

.create_notice {
    width: auto !important;
    box-shadow: none !important;
    background: none !important;
    div[class*="prefix"] {
        display: none !important; }
    div[class*="label"] {
        color: $neutrals4;
        @include caption-1; }
    div[class*="_row"] {
        padding-bottom: 30px;
        border-bottom: 1px solid $navy_blue;
        margin-bottom: 30px; }
    button {
        border-radius: 90px; } }

.search_container {
    position: relative; }
.search_result {
    position: absolute;
    width: 100%;
    margin-top: 25px;
    min-height: 250px;
    background: $neutrals6;
    @include dark {
        background: $neutrals1_5; }

    .loader {
        margin: 0 auto;
        margin-top: 30px; } }

.search_result_item {
    width: 100%;
    display: flex;
    align-items: center;
    color: $neutrals8;
    img {
        width: 50px;
        margin: 10px; }
    &:hover {
        background-color: $neutrals4; }
    &:not(:last-child) {
        border-bottom: 1px solid $neutrals4; } }
