.dark-mode :global(svg) {
  /*all: unset;*/
  fill: red; }


svg.dark-mode {
  fill: red !important; }
// common styles
@import "./reset";
@import "./helpers";
@import "./common";

// global classes
@import "./blocks/section";
@import "./blocks/container";
@import "./blocks/button";
@import "./blocks/title";
@import "./blocks/slick";
@import "./blocks/status";
@import "./blocks/currency";



