[class^="section"] {
    padding: 60px 0;
    @include x {
        padding: 56px 0; }
    @include m {
        padding: 30px 0; } }

.section-bg {
    background: $neutrals7;
    //+dark-common
    //    background: $neutrals2
    @include dark-common {
        background: transparent; } }

.section-pb {
    padding-top: 0;
    @include d {
        padding-top: 0; }
    @include m {
        padding-top: 0; } }

.section-pt80 {
    padding-top: 80px;
    @include d {
        padding-top: 64px; }
    @include m {
        padding-top: 32px; } }

.section-pt0 {
    padding-top: 0px; }
