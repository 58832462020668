@import "../../styles/helpers";

.section {
  overflow: hidden;
  animation: opacity 500ms ease-in both;
  @include m {
    padding-top: 48px; } }

.preview {
  text-align: center;
  margin-bottom: 36px;
  @include d {
    margin: 0 -64px 30px; }
  @include t {
    margin-bottom: 64px; }
  @include m {
    margin: 0 -160px 32px; }

  img {
    width: 100%; } }

.wrap {
  max-width: 736px;
  margin: 0 auto 96px;
  text-align: center;
  @include m {
    margin-bottom: 64px; } }

.title {
  margin-bottom: 8px; }

.info {
  margin-bottom: 24px;
  color: $neutrals4; }


@keyframes opacity {
  0% {
    opacity: 0.01; }
  100% {
    opacity: 1; } }
